<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_39599_55432)">
      <path
        d="M19 6H1V19H19V6Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
        fill="none"
      />
      <path
        d="M14.1309 1H6.13086V6H14.1309V1Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
        fill="none"
      />
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
