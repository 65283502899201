export const useCustomSwitchLocalePath = () => {
  const router = useRouter();

  const customSwitchLocalePaths = useState<Record<string, string> | undefined>(
    "customSwitchLocalePaths",
  );

  const setSwitchLocalePaths = (paths: Record<string, string>) => {
    customSwitchLocalePaths.value = paths;
  };

  const getSwitchLocalePath = (code: string) => {
    const customRoute = customSwitchLocalePaths.value?.[code];
    const switchLocalePath = useSwitchLocalePath({
      route: customRoute ? router.resolve(customRoute) : undefined,
    });

    return switchLocalePath(code);
  };

  return { setSwitchLocalePaths, getSwitchLocalePath };
};
